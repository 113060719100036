/* eslint-disable max-lines */

import { CustomerFacingCancellationReason, SingleLanguageLocalization } from './types';

/**
 * ------------- ITALIAN -----------
 *| |_______________________________
 *| ||MMMMMMMMMM          IIIIIIIIII|
 *| ||MMMMMMMMMM          IIIIIIIIII|
 *| ||MMMMMMMMMM          IIIIIIIIII|
 *| ||MMMMMMMMMM          IIIIIIIIII|
 *| ||MMMMMMMMMM          IIIIIIIIII|
 *| ||MMMMMMMMMM          IIIIIIIIII|
 *| ||MMMMMMMMMM          IIIIIIIIII|
 *| ||MMMMMMMMMM          IIIIIIIIII|
 *| ||MMMMMMMMMM          IIIIIIIIII|
 *| |'""""""""""""""""""""""""""""""'
 */
export const it: SingleLanguageLocalization = {
  product: {
    location: 'Posizione',
    noLocationSelected: 'Nessuna posizione scelta',
    invalidBookingId: 'Prenotazione non trovata - ID prenotazione non valido',
    confirm: 'Conferma',
    Booking: 'Prenotazione',
    booking: 'prenotazione',
    yourBooking: 'La tua prenotazione',
    Giftcard: 'Carta regalo',
    giftcard: 'carta regalo',
    campaign: 'campagna',
    voucher: 'buono',
    refund: 'Voucher',
    membership: 'membri',
    'membership-campaign': 'campagna',
    yourGiftcard: 'La tua carta regalo',
    configHeader: 'Barche e durata',
    durationHeading: 'Barche e durata',
    dateAndTime: 'Data e orario di partenza',
    yourInformation: 'Le tue informazioni',
    extras: 'Extra',
    extrasAndRules: 'Extra e regole',
    finalize: 'Finalizza ordine',
    completeBooking: `La tua prenotazione è quasi confermata.

      Completa la prenotazione cliccando il pulsante a destra.`,
    completeBookingMobile: `La tua prenotazione è quasi confermata.

    Completa la prenotazione attraverso la panoramica dell'ordine in basso.`,
    numberOfUnits: 'N. di barche',
    personsPerUnit: '8 pers. per barca',
    boat: 'barca',
    boats: 'barche',
    person: 'persona',
    people: 'persone',
    hour: 'ora',
    hours: 'ore',
    minutes: 'minuti',
    hourAbbr: 'h',
    minuteAbbr: 'm',
    chooseLocation: 'Scegli la posizione',
    currentBooking: 'Prenotazione attuale',
    show: 'Mostra',
    hide: 'Nascondi',
    currentbooking: 'prenotazione attuale',
    currentgiftcard: 'buono regalo attuale',
    configureGiftcard: 'Configura la tua carta regalo',
    durationInHours: 'Durata',
    acceptTerms: 'Accetta termini e condizioni',
    subscribeToNewsletter:
      'Desidero ricevere la newsletter di GoBoat con ispirazioni marittime e informazioni sugli eventi',
    yes: 'Sì',
    no: 'No',
    subscribeToNewsletterShort: 'Iscriviti alla newsletter',
    next: 'Avanti',
    prev: 'Precedente',
    page: 'Pagina',
    addToOrder: "Aggiungi all'ordine",
    added: 'Aggiunto',
    haveAVoucher: 'Usa un buono',
    voucherCode: 'Codice voucher',
    applyVoucher: 'Applica il voucher',
    voucherApplied: 'Un voucher è stato applicato',
    toBePaid: 'Da pagare',
    confirmPayment: 'Conferma pagamento',
    confirmBooking: 'Conferma prenotazione',
    confirmGiftcard: 'Conferma buono regalo',
    confirmMembership: 'Conferma membri',
    cancelBooking: 'Annulla prenotazione',
    cancelTextVoucherRefund1: `Sei sicuro di voler annullare la tua prenotazione?

      Riceverai un'e-mail con un voucher del valore di `,
    cancelTextVoucherRefund2: `
      Il voucher scade tra `,
    cancelTextVoucherRefund3: ` mesi.`,
    cancelTextRefund1: `
      Se desideri un rimborso devi contattare il servizio clienti.`,
    cancelTextRefund2: `
      Poiché il tempo di uscita è inferiore a `,
    cancelTextRefund3: ` ore per le quali non è possibile richiedere il rimborso.`,
    cancelTextNoRefund1: `Sei sicuro di voler annullare la tua prenotazione?

      Poiché il tempo per salpare è inferiore a `,
    cancelTextNoRefund2: ` ore non è stato possibile elaborare il pagamento per la tua prenotazione.
      La tua prenotazione verrà annullata e non verrà effettuato alcun rimborso.`,
    canceledBooking: 'Ci dispiace vederti andare via!',
    canceledTextVoucherRefund: `Un voucher è stato emesso e ti verrà inviato via email a breve.

Capiamo che i piani possono cambiare e ci piacerebbe sapere perché hai dovuto annullare la tua prenotazione con noi. Il tuo feedback è inestimabile e ci aiuta a migliorare i nostri servizi.`,
    canceledTextNoRefund:
      'Capiamo che i piani possono cambiare e ci piacerebbe sapere perché hai dovuto annullare la tua prenotazione con noi. Il tuo feedback è inestimabile e ci aiuta a migliorare i nostri servizi.',
    canceledTextInitial:
      'La prenotazione è stata annullata, clicca il pulsante sottostante se desideri prenotare una nuova esperienza.',
    cancellationFeedbackPrompt:
      'Per favore, facci sapere il motivo della tua cancellazione cliccando su una delle opzioni qui sotto:',
    cancellationThankYou:
      'Grazie per il tuo feedback. Apprezziamo il tuo tempo e speriamo di poterti servire meglio in futuro.',
    canceledButtonTextInitial: 'Prenota una nuova esperienza',
    pleaseWait: 'Attendere...',
    positiveBalance1: 'La tua prenotazione ha un saldo positivo.',
    positiveBalance2Refund:
      'Puoi utilizzare questo saldo per acquistare extra, o puoi richiedere un rimborso contattando il servizio clienti.',
    positiveBalance2VoucherRefund:
      'Puoi utilizzare questo saldo per acquistare extra, o puoi richiedere un voucher contattando il servizio clienti.',
    positiveBalance3:
      'Puoi anche richiedere il rimborso anticipato inviando una email a ',
    neutralBalance: 'Il tuo ordine è saldato - nessun pagamento aggiuntivo richiesto.',
    refundEmailSubject: 'Rimborso - ID prenotazione: ',
    priceForSailing: 'Prezzo per la navigazione',
    priceForExtras: 'Prezzo per gli extra',
    priceForBooking: 'Prezzo per la prenotazione',
    cancellationWarranty: 'Garanzia di cancellazione',
    cancellationWarrantyHeading: 'Non sei sicuro di potercela fare?',
    cancellationWarrantyText1:
      'Aggiungendo la nostra garanzia di annullamento, potrai riprogrammare o annullare la tua prenotazione fino a ',
    cancellationWarrantyText2: ' ore prima della partenza.',
    thankYou: 'Grazie',
    captain: 'Capitano',
    bookingCompleted:
      "La tua prenotazione è completata. Non vediamo l'ora di vederti al molo.",
    practicalInfo1: 'Assicurati di trovarti presso la base di noleggio ',
    practicalInfo2: " almeno 15 minuti prima dell'orario di partenza.",
    confirmationEmailSentTo: 'Una email di conferma è stata inviata a',
    learnMore: 'Scopri di più',
    cancel: 'Annulla',
    fetching: 'Caricamento',
    loading: 'Caricamento',
    selectDateToViewTimeslots: 'Seleziona una data per visualizzare gli slot disponibili',
    yourInformationBookingHeld:
      'Una volta che hai compilato le tue informazioni il tuo orario di partenza sarà riservato per 10 minuti.',
    noOpenDays: 'Non ci sono giorni aperti nel mese selezionato',
    defaultPaymentSuccessText: `Grazie per la tua prenotazione non vediamo l'ora di darti il benvenuto a bordo!

      Ricorda di arrivare 15 minuti prima dell'orario di partenza.

      Se hai domande prima della tua navigazione contattaci.`,
    OR: 'O',
    left: 'rimasti',
    goToCheckout: 'Vai al checkout',
    bookingHeldHeading: 'Il tuo orario di partenza è riservato',
    bookingHeldText:
      'Dopo 10 minuti, non possiamo garantire che il tuo orario di partenza sia ancora disponibile.',
    bookingHeldTextConfirmBooking: 'Per favore, continua per confermare la prenotazione.',
    allRightsReserved: 'Tutti i diritti riservati a',
    cookieSettings: 'Impostazioni cookie',
    custom: 'Personalizzato',
    amount: 'Importo',
    customAmount: 'Importo personalizzato',
    selectPickUpLocation: 'Scegliere dove imbarcarsi',
    selectDropOffLocation: 'Scegliete dove sbarcare',
    select: 'Seleziona',
    selected: 'Selezionato',
    includedInPrice: 'Incluso nel prezzo',
    viewOnMap: 'Vedi sulla mappa',
    pickupLocation: 'Luogo di imbarco',
    dropoffLocation: 'Luogo di sbarco',
    from: 'Da',
    enterYourPaymentDetails: 'Inserisci i tuoi dati di pagamento',
    roomForUpTo: 'Spazio per un massimo di',
    addDesiredExtras: 'Aggiungi i tuoi extra desiderati qui sotto.',
    whereWouldYouLikeToSail: 'Dove ti piacerebbe navigare?',
    howManyBoats: 'Quante barche?',
    howManyHours: 'Quante ore?',
    customerFormHeading: 'A quale nome possiamo confermare la tua prenotazione?',
    customerFormText:
      "L'email è per la conferma, il numero di telefono è per la sicurezza.",
  },
  giftcard: {
    recipientInformation: 'Informazioni sul destinatario',
    optional: 'Opzionale',
    defaultPaymentSuccessText:
      "Grazie per l'acquisto di una carta regalo per un'esperienza unica e sostenibile con GoBoat.",
  },
  membership: {
    membership: 'membri',
    yourMembership: 'La tua iscrizione',
    defaultPaymentSuccessText: 'Grazie per esserti iscritto a GoBoat Plus.',
  },
  sauna: {
    duration: 'Durata',
    dateAndTime: 'Data e ora',
    selectDateToViewTimeslots: 'Seleziona una data per visualizzare gli slot disponibili',
    defaultPaymentSuccessText: `Grazie per la prenotazione. Non vediamo l'ora di darti il benvenuto.

      Ricorda di presentarti 15 minuti prima dell'orario di partenza.

      Se hai domande sulla tua prenotazione non esitare a contattarci.`,
    boat: 'persona',
    boats: 'persone',
    numberOfUnits: 'Numero di persone',
    personsPerUnit: 'Capacità: 8 persone',
    durationHeading: 'Persone e durata',
  },
  customer: {
    firstname: 'Nome',
    lastname: 'Cognome',
    phone: 'Numero di telefono',
    email: 'E-mail',
    birthDate: 'Data di nascita',
    birthDatePattern: '(AAAA-MM-GG)',
    zip: 'CAP',
    province: 'Provincia',
    streetAddress: 'Indirizzo',
    city: 'Città',
    pin: 'Codice Fiscale',
  },
  recipient: {
    name: 'Nome',
  },
  price: {
    price: 'Prezzo',
    allIncluded: 'Prezzo tutto incluso',
    paid: 'Pagato',
    toBePaid: 'Da pagare',
    toBeRefunded: 'Da rimborsare',
    confirm: 'Conferma',
  },
  lookup: {
    noBookingTitle: 'Hmm non riusciamo a trovare questa prenotazione…',
    noBookingContent: "Non sembra che ci sia alcuna prenotazione nel sistema con l'ID:",
    noRequestTitle: 'Cerchi la tua prenotazione?',
    noRequestContent: 'Non sembra che tu abbia richiesto alcuna prenotazione.',
    giefId:
      "Fornisci l'ID della prenotazione dalla email di conferma che hai ricevuto da noi.",
    getBooking: 'Ottieni prenotazione',
  },
  error: {
    NoShopId: "L'ID del negozio non è valido.",
    NoBoatCount: 'La prenotazione non contiene imbarcazioni.',
    NoAvailableTimeslots: 'Nessuna fascia oraria disponibile.',
    SlotNoLongerAvailable:
      'La fascia oraria selezionata non è disponibile. Seleziona una fascia oraria diversa.',
    BookingNotCreated:
      'Si è verificato un errore. La prenotazione non è stata creata. Aggiorna la pagina e riprova.',
    SelectStartTime: 'Seleziona un orario di inizio per la tua prenotazione.',
    WeekdayNotValid:
      'Il giorno della settimana selezionato non è idoneo per questo voucher.',
    VoucherAlreadyAdded: 'Un voucher è già stato aggiunto a questa prenotazione.',
    VoucherNotValidForTimeslot:
      "Il voucher non è valido per l'orario di partenza selezionato.",
    PercentVoucherAlreadyUsed: 'Un voucher è già stato aggiunto a questa prenotazione.',
    VoucherDepleted: 'Il voucher non è più valido.',
    VoucherLocationInvalid: 'Il voucher non è valido per la posizione selezionata.',
    VoucherWeekdayInvalid:
      'Il voucher non è valido per il giorno della settimana selezionato.',
    VoucherDateInvalid: 'Il voucher non è valido per la data selezionata.',
    VoucherValidForInvalid: 'Il voucher non è valido.',
    VoucherInvalid: 'Il voucher non è valido.',
    InvalidVoucherCombination: 'Questi voucher non possono essere utilizzati insieme.',
    VoucherAlreadyApplied: 'Il voucher è già stato applicato alla prenotazione.',
    NotValidPayment:
      'La richiesta di pagamento non è valida - la transazione non è stata completata.',
    ShopNotOpen: 'Il negozio non è aperto nella data selezionata.',
    ShopNoLongerOpen:
      'Il negozio non ha più orari di apertura validi nella data selezionata.',
    ShopHasNoPriceModels: 'Il negozio non ha modelli di prezzo.',
    NoShopsFound: 'Nessuna posizione trovata.',
    UnknownError: 'Si è verificato un errore sconosciuto. Aggiorna la pagina e riprova.',
    PriceMismatchError:
      'Il prezzo per la tua fascia oraria selezionata è cambiato. Aggiorna la pagina e riprova.',
    NotAvailable: 'Non disponibile',
    DepartureTimesNotAvailable:
      'Questi orari di partenza non sono disponibili con la durata selezionata:',
    NotAvailableForOnlineBooking: 'Non disponibili per le prenotazioni online',
    PersonalDetailsInvalid: 'I dettagli personali non sono validi.',
    Alcoholerror: 'La quantitá di alcol supera il limite consentito.',
    WarrantyWasRemoved1:
      "La garanzia di cancellazione è stata rimossa perché l'orario di partenza attuale è inferiore alle",
    WarrantyWasRemoved2: 'ore.',
    WarrantyCannotBeAdded1:
      "La garanzia di annullamento non può essere aggiunta se l'orario di partenza è inferiore alle",
    WarrantyCannotBeAdded2: 'ore.',
    WarrantyMissingDateAndTime:
      'Selezionare una data e un orario di partenza prima di aggiungere la garanzia di cancellazione.',
    WarrantyInvalidDateAndTime:
      "La garanzia di cancellazione non può essere aggiunta alla data e all'orario di partenza selezionati.",
  },
  faq: {
    title: 'Come acquistare un certificato regalo',
    p1: 'La carta regalo e il codice univoco saranno inviati alla tua casella di posta con istruzioni su come utilizzarli. Conserva questa email in un luogo sicuro, poiché è la tua ricevuta di acquisto. La carta regalo può essere riscattata per il valore monetario del tuo acquisto oggi entro',
    p2: 'mesi per il noleggio di barche presso la posizione che hai selezionato. Non può essere riscattata in contanti.',
    campaign:
      'La carta regalo e il codice univoco saranno inviati alla tua casella di posta con istruzioni su come utilizzarli. Conserva questa email in un luogo sicuro, poiché è la tua ricevuta di acquisto. La carta regalo può essere riscattata per il valore monetario del tuo acquisto oggi per il noleggio di barche presso la posizione che hai selezionato. Vedi la data di scadenza sulla carta regalo. Non può essere riscattata in contanti.',
    show: 'Mostra FAQ',
    hide: 'Nascondi FAQ',
  },
  factBox: {
    close: 'Chiudi',
    seeMore: 'Vedi di più',
  },
  meta: {
    description:
      "Noleggia una barca presso GoBoat e vivi un'esperienza unica sull'acqua. Le barche possono trasportare fino a otto persone e non è richiesta licenza né esperienza per guidare la barca.",
  },
  faqList: [
    'Scegli la durata del noleggio per la carta regalo',
    'Inserisci il tuo indirizzo e-mail (questo dovrebbe essere il tuo indirizzo e-mail non la persona a cui regalerai la carta)',
    'Inserisci i dettagli della tua carta di credito e conferma',
  ],

  cancellationReason: {
    [CustomerFacingCancellationReason.WEATHER]: 'Meteo',
    [CustomerFacingCancellationReason.SCHEDULE]: 'Cambio di piani',
    [CustomerFacingCancellationReason.SICKNESS]: 'Malattia',
    [CustomerFacingCancellationReason.OTHER]: 'Altro',
  },
};
