/* eslint-disable max-lines */
import { CancellationReason } from '../Utils/graphql-sdk';

export enum ProductLocalization {
  location = 'location',
  noLocationSelected = 'noLocationSelected',
  invalidBookingId = 'invalidBookingId',
  confirm = 'confirm',
  Booking = 'Booking',
  booking = 'booking',
  yourBooking = 'yourBooking',
  Giftcard = 'Giftcard',
  giftcard = 'giftcard',
  campaign = 'campaign',
  voucher = 'voucher',
  refund = 'refund',
  membership = 'membership',
  'membership-campaign' = 'membership-campaign',
  yourGiftcard = 'yourGiftcard',
  configHeader = 'configHeader',
  durationHeading = 'durationHeading',
  dateAndTime = 'dateAndTime',
  yourInformation = 'yourInformation',
  extras = 'extras',
  extrasAndRules = 'extrasAndRules',
  finalize = 'finalize',
  completeBooking = 'completeBooking',
  completeBookingMobile = 'completeBookingMobile',
  numberOfUnits = 'numberOfUnits',
  personsPerUnit = 'personsPerUnit',
  boat = 'boat',
  boats = 'boats',
  person = 'person',
  people = 'people',
  hour = 'hour',
  hours = 'hours',
  minutes = 'minutes',
  hourAbbr = 'hourAbbr',
  minuteAbbr = 'minuteAbbr',
  chooseLocation = 'chooseLocation',
  currentBooking = 'currentBooking',
  show = 'show',
  hide = 'hide',
  currentbooking = 'currentbooking',
  currentgiftcard = 'currentgiftcard',
  configureGiftcard = 'configureGiftcard',
  durationInHours = 'durationInHours',
  acceptTerms = 'acceptTerms',
  subscribeToNewsletter = 'subscribeToNewsletter',
  yes = 'yes',
  no = 'no',
  subscribeToNewsletterShort = 'subscribeToNewsletterShort',
  next = 'next',
  prev = 'prev',
  page = 'page',
  addToOrder = 'addToOrder',
  added = 'added',
  haveAVoucher = 'haveAVoucher',
  voucherCode = 'voucherCode',
  applyVoucher = 'applyVoucher',
  voucherApplied = 'voucherApplied',
  toBePaid = 'toBePaid',
  confirmPayment = 'confirmPayment',
  confirmBooking = 'confirmBooking',
  confirmGiftcard = 'confirmGiftcard',
  confirmMembership = 'confirmMembership',
  cancelBooking = 'cancelBooking',
  cancelTextVoucherRefund1 = 'cancelTextVoucherRefund1',
  cancelTextVoucherRefund2 = 'cancelTextVoucherRefund2',
  cancelTextVoucherRefund3 = 'cancelTextVoucherRefund3',
  cancelTextRefund1 = 'cancelTextRefund1',
  cancelTextRefund2 = 'cancelTextRefund2',
  cancelTextRefund3 = 'cancelTextRefund3',
  cancelTextNoRefund1 = 'cancelTextNoRefund1',
  cancelTextNoRefund2 = 'cancelTextNoRefund2',
  canceledBooking = 'canceledBooking',
  canceledTextVoucherRefund = 'canceledTextVoucherRefund',
  canceledTextNoRefund = 'canceledTextNoRefund',
  canceledTextInitial = 'canceledTextInitial',
  canceledButtonTextInitial = 'canceledButtonTextInitial',
  cancellationFeedbackPrompt = 'cancellationFeedbackPrompt',
  cancellationThankYou = 'cancellationThankYou',
  pleaseWait = 'pleaseWait',
  positiveBalance1 = 'positiveBalance1',
  positiveBalance2Refund = 'positiveBalance2Refund',
  positiveBalance2VoucherRefund = 'positiveBalance2VoucherRefund',
  positiveBalance3 = 'positiveBalance3',
  neutralBalance = 'neutralBalance',
  refundEmailSubject = 'refundEmailSubject',
  priceForSailing = 'priceForSailing',
  priceForExtras = 'priceForExtras',
  priceForBooking = 'priceForBooking',
  cancellationWarranty = 'cancellationWarranty',
  cancellationWarrantyHeading = 'cancellationWarrantyHeading',
  cancellationWarrantyText1 = 'cancellationWarrantyText1',
  cancellationWarrantyText2 = 'cancellationWarrantyText2',
  thankYou = 'thankYou',
  captain = 'captain',
  bookingCompleted = 'bookingCompleted',
  practicalInfo1 = 'practicalInfo1',
  practicalInfo2 = 'practicalInfo2',
  confirmationEmailSentTo = 'confirmationEmailSentTo',
  learnMore = 'learnMore',
  cancel = 'cancel',
  fetching = 'fetching',
  loading = 'loading',
  selectDateToViewTimeslots = 'selectDateToViewTimeslots',
  yourInformationBookingHeld = 'yourInformationBookingHeld',
  noOpenDays = 'noOpenDays',
  defaultPaymentSuccessText = 'defaultPaymentSuccessText',
  OR = 'OR',
  left = 'left',
  goToCheckout = 'goToCheckout',
  bookingHeldHeading = 'bookingHeldHeading',
  bookingHeldText = 'bookingHeldText',
  bookingHeldTextConfirmBooking = 'bookingHeldTextConfirmBooking',
  allRightsReserved = 'allRightsReserved',
  cookieSettings = 'cookieSettings',
  custom = 'custom',
  amount = 'amount',
  customAmount = 'customAmount',
  selectPickUpLocation = 'selectPickUpLocation',
  selectDropOffLocation = 'selectDropOffLocation',
  select = 'select',
  selected = 'selected',
  includedInPrice = 'includedInPrice',
  viewOnMap = 'viewOnMap',
  pickupLocation = 'pickupLocation',
  dropoffLocation = 'dropoffLocation',
  from = 'from',
  enterYourPaymentDetails = 'enterYourPaymentDetails',
  roomForUpTo = 'roomForUpTo',
  addDesiredExtras = 'addDesiredExtras',
  whereWouldYouLikeToSail = 'whereWouldYouLikeToSail',
  howManyBoats = 'howManyBoats',
  howManyHours = 'howManyHours',
  customerFormHeading = 'customerFormHeading',
  customerFormText = 'customerFormText',
}

export enum GiftcardLocalization {
  recipientInformation = 'recipientInformation',
  optional = 'optional',
  defaultPaymentSuccessText = 'defaultPaymentSuccessText',
}

export enum MembershipLocalization {
  membership = 'membership',
  yourMembership = 'yourMembership',
  defaultPaymentSuccessText = 'defaultPaymentSuccessText',
}

export enum SaunaLocalization {
  duration = 'duration',
  dateAndTime = 'dateAndTime',
  selectDateToViewTimeslots = 'selectDateToViewTimeslots',
  defaultPaymentSuccessText = 'defaultPaymentSuccessText',
  boat = 'boat',
  boats = 'boats',
  numberOfUnits = 'numberOfUnits',
  personsPerUnit = 'personsPerUnit',
  durationHeading = 'durationHeading',
}

export enum CustomerLocalization {
  firstname = 'firstname',
  lastname = 'lastname',
  phone = 'phone',
  email = 'email',
  birthDate = 'birthDate',
  birthDatePattern = 'birthDatePattern',
  zip = 'zip',
  province = 'province',
  streetAddress = 'streetAddress',
  city = 'city',
  pin = 'pin',
}

export enum RecipientLocalization {
  name = 'name',
}

export enum PriceLocalization {
  price = 'price',
  allIncluded = 'allIncluded',
  paid = 'paid',
  toBePaid = 'toBePaid',
  toBeRefunded = 'toBeRefunded',
  confirm = 'confirm',
}

export enum LookupLocalization {
  noBookingTitle = 'noBookingTitle',
  noBookingContent = 'noBookingContent',
  noRequestTitle = 'noRequestTitle',
  noRequestContent = 'noRequestContent',
  giefId = 'giefId',
  getBooking = 'getBooking',
}

export enum ErrorLocalization {
  NoShopId = 'NoShopId',
  NoBoatCount = 'NoBoatCount',
  NoAvailableTimeslots = 'NoAvailableTimeslots',
  SlotNoLongerAvailable = 'SlotNoLongerAvailable',
  BookingNotCreated = 'BookingNotCreated',
  SelectStartTime = 'SelectStartTime',
  WeekdayNotValid = 'WeekdayNotValid',
  VoucherAlreadyAdded = 'VoucherAlreadyAdded',
  VoucherNotValidForTimeslot = 'VoucherNotValidForTimeslot',
  PercentVoucherAlreadyUsed = 'PercentVoucherAlreadyUsed',
  VoucherDepleted = 'VoucherDepleted',
  VoucherLocationInvalid = 'VoucherLocationInvalid',
  VoucherWeekdayInvalid = 'VoucherWeekdayInvalid',
  VoucherDateInvalid = 'VoucherDateInvalid',
  VoucherValidForInvalid = 'VoucherValidForInvalid',
  VoucherInvalid = 'VoucherInvalid',
  InvalidVoucherCombination = 'InvalidVoucherCombination',
  VoucherAlreadyApplied = 'VoucherAlreadyApplied',
  NotValidPayment = 'NotValidPayment',
  ShopNotOpen = 'ShopNotOpen',
  ShopNoLongerOpen = 'ShopNoLongerOpen',
  ShopHasNoPriceModels = 'ShopHasNoPriceModels',
  NoShopsFound = 'NoShopsFound',
  UnknownError = 'UnknownError',
  PriceMismatchError = 'PriceMismatchError',
  NotAvailable = 'NotAvailable',
  DepartureTimesNotAvailable = 'DepartureTimesNotAvailable',
  NotAvailableForOnlineBooking = 'NotAvailableForOnlineBooking',
  PersonalDetailsInvalid = 'PersonalDetailsInvalid',
  Alcoholerror = 'Alcoholerror',
  WarrantyWasRemoved1 = 'WarrantyWasRemoved1',
  WarrantyWasRemoved2 = 'WarrantyWasRemoved2',
  WarrantyCannotBeAdded1 = 'WarrantyCannotBeAdded1',
  WarrantyCannotBeAdded2 = 'WarrantyCannotBeAdded2',
  WarrantyMissingDateAndTime = 'WarrantyMissingDateAndTime',
  WarrantyInvalidDateAndTime = 'WarrantyInvalidDateAndTime',
}

export function isErrorLocalization(value: string): value is ErrorLocalization {
  return value in ErrorLocalization;
}

export enum FAQLocalization {
  title = 'title',
  p1 = 'p1',
  p2 = 'p2',
  campaign = 'campaign',
  show = 'show',
  hide = 'hide',
}

export enum FactBoxLocalization {
  close = 'close',
  seeMore = 'seeMore',
}

export enum MetaLocalization {
  description = 'description',
}

export enum LocalizationCategory {
  product = 'product',
  giftcard = 'giftcard',
  membership = 'membership',
  sauna = 'sauna',
  customer = 'customer',
  recipient = 'recipient',
  price = 'price',
  lookup = 'lookup',
  error = 'error',
  faq = 'faq',
  factBox = 'factBox',
  meta = 'meta',
  cancellationReason = 'cancellationReason',
}

export enum CustomerFacingCancellationReason {
  WEATHER = CancellationReason.Weather,
  SCHEDULE = CancellationReason.Schedule,
  SICKNESS = CancellationReason.Sickness,
  OTHER = CancellationReason.Other,
}

export interface SingleLanguageLocalization {
  [LocalizationCategory.product]: Record<ProductLocalization, string>;
  [LocalizationCategory.giftcard]: Record<GiftcardLocalization, string>;
  [LocalizationCategory.membership]: Record<MembershipLocalization, string>;
  [LocalizationCategory.sauna]: Record<SaunaLocalization, string>;
  [LocalizationCategory.customer]: Record<CustomerLocalization, string>;
  [LocalizationCategory.recipient]: Record<RecipientLocalization, string>;
  [LocalizationCategory.price]: Record<PriceLocalization, string>;
  [LocalizationCategory.lookup]: Record<LookupLocalization, string>;
  [LocalizationCategory.error]: Record<ErrorLocalization, string>;
  [LocalizationCategory.faq]: Record<FAQLocalization, string>;
  [LocalizationCategory.factBox]: Record<FactBoxLocalization, string>;
  [LocalizationCategory.meta]: Record<MetaLocalization, string>;
  [LocalizationCategory.cancellationReason]: Record<
    CustomerFacingCancellationReason,
    string
  >;
  faqList: string[];
}
