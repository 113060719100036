import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route } from 'react-router-dom';
import 'focus-visible';
import 'whatwg-fetch';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import Providers from './providers';

import './index.css';
import './Style/GlobalStyles.css';
import performLegacyRedirect from './Utils/perform-legacy-redirect';
import { getConfig } from './Utils/utils';

const BookingWithState = lazy(() => import('./Pages/Booking'));
const BookingReskinWithState = lazy(() => import('./Pages/BookingReskin'));
const BookingEditWithState = lazy(() => import('./Pages/BookingEdit'));
const GiftcardWithState = lazy(() => import('./Pages/Giftcard'));
const MembershipWithState = lazy(() => import('./Pages/Membership'));
const MembershipPlanNotFound = lazy(() => import('./Pages/membership-plan-not-found'));

const { sentryEnvironment, sentryEnabled, sentryRelease } = getConfig();

if (sentryEnabled === 'true') {
  Sentry.init({
    dsn: 'https://5316663455af4091a50e3680ebac74f0@o1150551.ingest.sentry.io/6223537',
    integrations: [new BrowserTracing()],
    environment: sentryEnvironment,
    release: sentryRelease,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in banana production
    tracesSampleRate: 1.0,
  });
}

const App = () => {
  performLegacyRedirect([
    { from: '#!/bookingCreate', to: 'booking' },
    { from: '#!/giftcards', to: 'giftcard' },
    { from: '#!/payment', to: 'booking', dynamic: true },
  ]);

  return (
    <Providers>
      <Suspense fallback={<></>}>
        <Routes>
          <Route path="booking/:bookingId/*" element={<BookingEditWithState />} />
          <Route path="booking-reskin" element={<BookingReskinWithState />} />
          <Route path="booking" element={<BookingWithState />} />
          <Route path="giftcard" element={<GiftcardWithState />} />
          <Route path="goboat-plus/:planId" element={<MembershipWithState />} />
          <Route path="goboat-plus" element={<MembershipPlanNotFound />} />
          <Route path="sauna/:bookingId/*" element={<BookingEditWithState />} />
          <Route path="sauna" element={<BookingWithState />} />
        </Routes>
      </Suspense>
    </Providers>
  );
};

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);
root.render(<App />);
